<template>
  <NavBar :info="info"/>
  <div class="home">
    Here's the info
    {{info}}
  </div>
</template>

<script>
import NavBar from '@/components/nav/NavBar.vue';
// @ is an alias to /src
//import NavHeader from "@/components/NavHeader.vue"

export default {
  name: "HomeView",
  components: {
    NavBar
  },
  props: [
    'info'
  ]
};
</script>
