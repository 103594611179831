<template>
  <!--Root View, Place all hidden tags in here-->
  <HomeView :info="info"/>
</template>

<script>
import HomeViewVue from "./views/HomeView.vue";
export default {
  components: {
    HomeView: HomeViewVue
  },
  data(){
    return{
      info: {
        name: 'Blue Ridge Exotics',
        socials: {
          facebook: 'https://facebook.com',
          instagram: 'none',
          youtube: 'none',

        }
      }
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}
/*
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
*/
</style>
