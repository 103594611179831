<template>
    <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid"> <!--"d-flex justify-content-between"-->
            <a class="navbar-brand" href="#">
                <!--Slap brand logo here-->
                <img src="https://flyclipart.com/lizard-clipart-free-download-clip-art-on-clipartbarn-lizard-clipart-934064" 
                width="30" height="24" class="d-inline-block align-text-top"
                />
                {{info.name}}
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navItems" aria-controls="navItems" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navItems">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/shop">Inventory</router-link>
                    </li>
                    <li class="nav-item">
                        <SocialButtons/>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import SocialButtons from './SocialButtons.vue';
export default{
    props: [
        'info'
    ],
    components:{
        SocialButtons
    }
}
</script>