<template>
    <div class="row">
        <div class="col-auto">
            social media
        </div>
        <div class="col-auto">
            <i class="bi bi-facebook" />
        </div>
    </div>
</template>

<script>
export default{
    props: [
        'data'
        /* object containing series of <k,v> where k is social media identifier and v is link 
        {
            facebook: 'https://yourfacebooklink.com',
            instagram: 'none',
            ...
        }
        */
    ],

}
</script>